<template>
  <div>
    <CityDataSummary v-if="targetSurveyType == 1" />
    <FactFindingSummary v-else-if="targetSurveyType == 2" />
    <ElectricitySummary v-else-if="targetSurveyType == 3" />
    <RefrigerantSummary v-else-if="targetSurveyType == 4" />
  </div>
</template>

<script>
import CityDataSummary from "./CityDataSummary.vue";
import FactFindingSummary from "./FactFindingSummary.vue";
import ElectricitySummary from "./ElectricitySummary.vue";
import RefrigerantSummary from "./RefrigerantSummary.vue";

export default {
  components: {
    CityDataSummary,
    FactFindingSummary,
    ElectricitySummary,
    RefrigerantSummary,
  },

  data: () => ({
    // 調査種別リスト
    surveyType: [
      {text: "月次報告データ", value: 1},
      {text: "実態調査", value: 2},
      {text: "電力調査", value: 3},
      {text: "冷媒調査", value: 4},
    ],

    // 対象調査種別
    targetSurveyType: null,
  }),

  // ライフサイクルフック
  created: function () {
    this.targetSurveyType = this.$store.getters.getTargetSurveyType;
  },

  mounted: async function () {

  },

  methods: {
    
  },

  watch: {

  },
};
</script>

<style scoped>
/* ホバー効果を無効化 */
.no-hover-effect >>> tr:hover {
  background-color: transparent !important;
}
.v-data-table >>> .td-rowspan {
  border-bottom: 1px solid #888888 !important;
}
</style>
