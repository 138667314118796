<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>{{ surveyType.filter((e) => (e.value == targetSurveyType))[0].text }} 集計</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" sm="4" md="4" lg="4">
        <DatePicker
          label="集計年月"
          v-model="overview.targetMonth"
          format="YYYY年MM月"
          selectType="month"
          :clearable="false"
          :rules="[required]"
          :changeEvt="search"
          :readonly="true"
          suffix="分" 
        ></DatePicker>
      </v-col>
    </v-row>

    <!-- 日冷倉協報告 -->
<!--
    <v-form
      class="mt-5 mb-5"
      ref="formAssociation"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              日冷倉協報告
            </v-col>
            <v-col v-if="attribute == 2" cols="3" sm="3" md="3" lg="3">
              <v-select
                label="都道府県"
                v-model="associationPref"
                :items="prefList"
                dense
                :readonly="attribute == 1"
                :clearable="false"
                :rules="[requiredSelect]"
                :loading="loading"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetMonth" @click="getNichireisou()"><v-icon :small="true">mdi-download</v-icon>集計</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>
-->

    <!-- 運輸局報告 -->
    <v-form
      class="mt-5 mb-5"
      ref="formBureau"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              運輸局報告
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3">
              <v-select
                label="都道府県協会"
                v-model="bureauDistAssocId"
                :items="distAssocList"
                dense
                :readonly="false"
                :clearable="false"
                :rules="[requiredSelect]"
                :loading="loading"
                @change="resetBureauPref"
              ></v-select>
            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2">
              <v-select
                label="都道府県"
                v-model="bureauPref"
                :items="getFilPrefList(bureauDistAssocId)"
                item-text="prefName"
                item-value="prefId"
                dense
                :readonly="false"
                :clearable="false"
                :rules="[requiredSelect]"
                :loading="loading"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetMonth || (bureauDistAssocId == null)" @click="getTransportBureau()"><v-icon :small="true">mdi-download</v-icon>ダウンロード</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>

    <!-- 日冷倉協月次報告 -->
    <v-form
      class="mb-5"
      ref="formReportNichireisou"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              日冷倉協月次報告
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3">
              <v-select
                label="都道府県協会"
                v-model="reportNichireisouDistAssocId"
                :items="distAssocList"
                dense
                :readonly="false"
                :clearable="false"
                :rules="[requiredSelect]"
                :loading="loading"
                @change="resetReportNichireisouPref"
              ></v-select>
            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2">
              <v-select
                label="都道府県"
                v-model="reportNichireisouPref"
                :items="getFilPrefList(reportNichireisouDistAssocId)"
                item-text="prefName"
                item-value="prefId"
                dense
                :readonly="false"
                :clearable="false"
                :rules="[requiredSelect]"
                :loading="loading"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetMonth || (reportNichireisouDistAssocId == null)" @click="getReportNichireisou()"><v-icon :small="true">mdi-download</v-icon>ダウンロード</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>

    <!-- 集計分析 -->
    <v-form
      class="mb-5"
      ref="formBunseki"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              集計分析
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3">
              <v-select
                label="都道府県協会"
                v-model="bunsekiDistAssocId"
                :items="distAssocList"
                dense
                :readonly="false"
                :clearable="false"
                :rules="[requiredSelect]"
                :loading="loading"
                @change="resetBunsekiPref"
              ></v-select>
            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2">
              <v-select
                label="都道府県"
                v-model="bunsekiPref"
                :items="getFilPrefList(bunsekiDistAssocId)"
                item-text="prefName"
                item-value="prefId"
                dense
                :readonly="false"
                :clearable="false"
                :rules="[requiredSelect]"
                :loading="loading"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetMonth || (bunsekiDistAssocId == null)" @click="getBunseki()"><v-icon :small="true">mdi-download</v-icon>ダウンロード</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>

    <!-- 冷倉協DB -->
    <v-form
      class="mb-5"
      ref="formAssociation"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              冷倉協DB
            </v-col>
            <!-- <v-col cols="3" sm="3" md="3" lg="3">
              <v-select
                label="都道府県"
                v-model="dbPref"
                :items="prefList"
                dense
                :readonly="attribute == 1"
                :clearable="false"
                :rules="[requiredSelect]"
                :loading="loading"
              ></v-select>
            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2">
              <v-radio-group 
                v-model="dbAll"
                dense
                row
                :readonly="false"
                :rules="[]"
                :loading="loading"
                class="mt-0 pt-0"
              >
                <v-radio
                  label="単月"
                  :value="0"
                ></v-radio>
                <v-radio
                  label="全月"
                  :value="1"
                ></v-radio>
              </v-radio-group>
            </v-col> -->
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetMonth" @click="getAssociationDB()"><v-icon :small="true">mdi-download</v-icon>ダウンロード</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>

    <!-- 冷蔵需給表 -->
<!--
    <v-form
      class="mb-5"
      ref="formSupplyDemand"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              冷蔵需給表
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3">
              <v-select
                label="都道府県"
                v-model="supplyDemandPref"
                :items="prefList"
                dense
                :readonly="attribute == 1"
                :clearable="false"
                :rules="[requiredSelect]"
                :loading="loading"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetMonth" @click="fileDownload(2, '冷蔵需給表', '/storage/cityData/神奈川県冷蔵需給表.xlsx')"><v-icon :small="true">mdi-download</v-icon>集計</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>
-->

    <!-- 12都市DB -->
    <!-- <v-form
      v-if="attribute == 2"
      class="mb-5"
      ref="formCityDataDB"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              12都市DB
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetMonth" @click="fileDownload(3, '12都市DB', '/storage/cityData/１２都市ＤＢ.xlsx')"><v-icon :small="true">mdi-download</v-icon>集計</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form> -->

    <!-- 12都市グラフ -->
    <!-- <v-form
      v-if="attribute == 2"
      class="mb-5"
      ref="formCityDataGraph"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              12都市グラフ
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetMonth" @click="fileDownload(4, '12都市グラフ', '/storage/cityData/１２都市ｸﾞﾗﾌ(元表).xlsx')"><v-icon :small="true">mdi-download</v-icon>集計</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form> -->

    <!-- 余積 -->
    <!-- <v-form
      v-if="attribute == 2"
      class="mb-5"
      ref="formCoproduct"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              主要12都市受寄物庫腹利用状況
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetMonth" @click="fileDownload(5, '2021_10_12city_yoseki_mono.xlsx', '/storage/cityData/2021_10_12city_yoseki_mono.xlsx')"><v-icon :small="true">mdi-download</v-icon>集計</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form> -->

    <!-- 在庫 -->
    <!-- <v-form
      v-if="attribute == 2"
      class="mb-5"
      ref="formStock"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              主要12都市品目別入庫量
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetMonth" @click="fileDownload(6, '2021_10_12city_zaiko_mono.xlsx', '/storage/cityData/2021_10_12city_zaiko_mono.xlsx')"><v-icon :small="true">mdi-download</v-icon>集計</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form> -->

    <!------------------------------------------------------------------------>
    <!-- ぐるぐる -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" height="auto" id="vm--modal">
      <div>
        <h3 class="center mt-5 action-message">{{actionMessage}}</h3> 
        <Spinner size="150" class="spinner" ></Spinner>
      </div>
    </modal>

  </v-container>
</template>

<script>
import Debug from '../Lib/Debug'
import DatePicker from "./common/DatePicker.vue";
import Message from "../Lib/Message";
import Mixin from "../mixins/const.js";
import Download from "../model/download";
import Spinner from 'vue-simple-spinner'

import CityDataAssociationSum from "../model/cityDataAssociationSum";
import Massociation from "../model/m_association";

import saveAs from "file-saver";
import dayjs from "dayjs";

export default {
  components: {
    DatePicker,
    Spinner,
  },

  mixins: [Mixin],

  data: () => ({
    valid : true,
    loading: false,

    // 調査種別リスト
    surveyType: [
      {text: "月次報告データ", value: 1},
      {text: "実態調査", value: 2},
      {text: "電力調査", value: 3},
      {text: "冷媒調査", value: 4},
    ],

    // 集計種別リスト
    sumType: [
      {text: "日冷倉協報告", value: 0},
      {text: "運輸局報告", value: 1},
      {text: "冷蔵需給表", value: 2},
      {text: "12都市DB", value: 3},
      {text: "12都市グラフ", value: 4},
      {text: "余積", value: 5},
      {text: "在庫", value: 6},
    ],

    // 対象調査種別
    targetSurveyType: null,

    // 基本情報
    overview: {
      targetMonth: "",
    },

    // 協会ID
    associationId: null,

    // 冷協DB
    dbPref: null,
    // 単月・前月
    dbAll: 0,
    // 日冷倉協報告
    associationPref: null,
    // 運輸局
    bureauPref: null,
    // 需給
    supplyDemandPref: null,

    reportNichireisouPref: null,
    bunsekiPref: null,

    // 会社一覧
    companyList: [
      { text: "○○冷蔵", value: "010550" },
      { text: "▲▲冷蔵", value: "010080" },
      { text: "××冷蔵", value: "280074" },
      { text: "冷蔵", value: "000001" },
    ],
    // 対象会社
    targetCompany: "",

    actionMessage: '',

    // 地区協会関連 対象協会ID
    bureauDistAssocId: null,
    reportNichireisouDistAssocId: null,
    bunsekiDistAssocId: null,
    // 地区協会関連 対象協会ID一覧
    distAssocList: [],
    // 地区協会関連 対象都道府県一覧
    distPrefList: [],
  }),

  // ライフサイクルフック
  created: function () {
    Debug.log("created");
    this.targetSurveyType = this.$store.getters.getTargetSurveyType;
    this.overview.targetMonth = this.$route.params.targetMonth;
    this.attribute = this.$store.getters.getAttribute;  // 属性

    if(this.overview.targetMonth == null) {
      this.overview.targetMonth = dayjs().format('YYYY-MM');  // 実施年月
    }

    // 属性による設定
    if(this.attribute == 1) {
      this.associationId = this.$store.getters.getAssociation.association_id;     // 協会ID
      // 都道府県協会ユーザは自協会の都道府県のみ
      const association = this.$store.getters.getAssociation;
      this.dbPref = association.pref_id;                        // 冷協 都道府県
      this.associationPref = association.pref_id;               // 日冷倉協報告 都道府県
      //this.bureauPref = association.pref_id;                    // 運輸局 都道府県
      this.supplyDemandPref = association.pref_id;              // 需給 都道府県
    } else {
      // 日本冷蔵倉庫協会ユーザは初期値を設定しない
    }
    Debug.log(this.associationPref);
  },

  mounted: async function () {
    this.initSelectList();
  },

  methods: {
    async initSelectList() {
      Debug.log("function[initSelectList]");
      this.loading = true;

      // DBからデータ取得
      try {
        const res = await Massociation.getDistAssocId(this.associationId);
        Debug.log(res.data);

        if (res.data) {
          // 地区協会関連 対象協会ID一覧
          this.distAssocList = res.data.distAssocList;
          this.distPrefList = res.data.distPrefList
          Debug.log(this.distAssocList);
          Debug.log(this.distPrefList);

          if(this.distAssocList.length > 0) {
            this.bureauDistAssocId = this.distAssocList[0].value;
            this.reportNichireisouDistAssocId = this.distAssocList[0].value;
            this.bunsekiDistAssocId = this.distAssocList[0].value;
          }
          if(this.distPrefList.length > 0) {
            this.bureauPref = this.getFilPrefList(this.bureauDistAssocId)[0].prefId;
            this.reportNichireisouPref = this.getFilPrefList(this.reportNichireisouDistAssocId)[0].prefId;
            this.bunsekiPref = this.getFilPrefList(this.bunsekiDistAssocId)[0].prefId;
          }
        }
      } catch (error) {
        Message.err(error, "地区協会関連 対象協会IDを取得できませんでした");
      }

      this.loading = false;
    },

    loadData() {
      Debug.log("function[loadData]");
    },

    search() {
      Debug.log("function[search]");
      if(this.overview.targetMonth == "") return;
      this.loadData();
    },

    getFilPrefList(v) {
      return this.distPrefList.filter(item => item.associationId == v);
    },
    /** 都道府県のリセット */
    resetBureauPref() {
      this.bureauPref = this.getFilPrefList(this.bureauDistAssocId)[0].prefId;
    },
    resetReportNichireisouPref() {
      this.reportNichireisouPref = this.getFilPrefList(this.reportNichireisouDistAssocId)[0].prefId;
    },
    resetBunsekiPref() {
      this.bunsekiPref = this.getFilPrefList(this.bunsekiDistAssocId)[0].prefId;
    },

    /** 冷倉協DB */
    async getAssociationDB() {
      this.actionMessage = "冷倉協DBを作成しています。\nしばらくお待ちください。"
      this.$modal.show('modal-dialog');

      // EXCEL出力
      CityDataAssociationSum.sumAssociationDB(this.associationId, this.overview.targetMonth)
        .then(res =>{
          if (res && res.data) {
            if (!confirm("作成した冷倉協DBをダウンロードします。よろしいですか？")) return;
            
            const file_info = res.data;
            Debug.log2("Download ファイル情報->", file_info);

            // ファイルダウンロード
            this.download(file_info);
          }
        })
        .catch((err) => {
          Message.err(err, "冷倉協DBを作成できませんでした");
        })
        .finally(() => {
          this.$modal.hide('modal-dialog');
          this.loading = false;
        });
    },

    // /** 日冷倉協報告 */
    // async getNichireisou() {
    //   this.loading = true;
    //   try {
    //     const res = await CityDataAssociationSum.sumNichireisou(this.overview.targetMonth, this.associationPref);

    //     if (res.status == 200 && res.data) {
    //       // 作成に成功したらExcelダウンロード
    //       Debug.log(res.data);
    //       const downloadFile = res.data;
    //       Download.download(downloadFile)
    //         .then(res => {
    //           saveAs(res.data, '日冷倉協報告.xlsx');
    //         })
    //         .catch((err) => {
    //           Message.err(err, "ダウンロードできませんでした");
    //       })
    //     }
    //   } catch (error) {
    //     Message.err(error, "日冷倉協報告を作成できませんでした");
    //   }

    //   this.loading = false;
    // },

    /** 運輸局報告 */
    async getTransportBureau() {
      this.loading = true;


      // EXCEL出力
      Debug.log2('this.bureauPref', this.bureauPref);
      CityDataAssociationSum.getTransportBureau(this.associationId, this.bureauDistAssocId, this.bureauPref, this.overview.targetMonth)
          .then(res =>{
            if (res && res.data) {
              if (!confirm("作成した運輸局報告をダウンロードします。よろしいですか？")) return;
              
              const file_info = res.data;
              Debug.log2("Download ファイル情報->", file_info);

              // ファイルダウンロード
              this.download(file_info);
            }
          })
          .catch((err) => {
            Message.err(err, "運輸局報告を作成できませんでした");
          })
          .finally(() => {
            // this.$modal.hide('modal-dialog');
            this.loading = false;
          });

      // try {
      //   const res = await CityDataAssociationSum.getTransportBureau(this.associationId, this.overview.targetMonth);

      //   if (res.status == 200 && res.data) {
      //     // 作成に成功したらExcelダウンロード
      //     Debug.log(res.data);
      //     const downloadFile = res.data;
      //     Download.download(downloadFile)
      //       .then(res => {
      //         saveAs(res.data, '日冷倉協報告.xlsx');
      //       })
      //       .catch((err) => {
      //         Message.err(err, "ダウンロードできませんでした");
      //     })
      //   }
      // } catch (error) {
      //   Message.err(error, "日冷倉協報告を作成できませんでした");
      // }
    },

    /** 日冷倉協月次報告 */
    async getReportNichireisou() {
      this.loading = true;

      // EXCEL出力
      CityDataAssociationSum.getReportNichireisou(this.associationId, this.reportNichireisouDistAssocId, this.reportNichireisouPref, this.overview.targetMonth)
          .then(res =>{
            if (res && res.data) {
              if (!confirm("作成した日冷倉協月次報告をダウンロードします。よろしいですか？")) return;
              
              const file_info = res.data;
              Debug.log2("Download ファイル情報->", file_info);

              // ファイルダウンロード
              this.download(file_info);
            }
          })
          .catch((err) => {
            Message.err(err, "日冷倉協月次報告を作成できませんでした");
          })
          .finally(() => {
            // this.$modal.hide('modal-dialog');
            this.loading = false;
          });
    },

    /** 集計分析 */
    async getBunseki() {
      this.loading = true;

      // EXCEL出力
      CityDataAssociationSum.getBunseki(this.associationId, this.bunsekiDistAssocId, this.bunsekiPref, this.overview.targetMonth)
          .then(res =>{
            if (res && res.data) {
              if (!confirm("作成した集計分析をダウンロードします。よろしいですか？")) return;
              
              const file_info = res.data;
              Debug.log2("Download ファイル情報->", file_info);

              // ファイルダウンロード
              this.download(file_info);
            }
          })
          .catch((err) => {
            Message.err(err, "集計分析を作成できませんでした");
          })
          .finally(() => {
            // this.$modal.hide('modal-dialog');
            this.loading = false;
          });
    },


    //-------------------------------------------------------------------------
    // ファイルダウンロード
    async download(file_info) {
      Debug.log("function[download]");

      // ダウンロード
      Download.download(file_info.Download_path)
        .then(res => {
          saveAs(res.data, file_info.File_name);
        })
        .catch((err) => {
          Message.err(err, "ダウンロードできませんでした");
        })

    },
  },

  watch: {

  },
};
</script>

<style scoped>
/* ホバー効果を無効化 */
.no-hover-effect >>> tr:hover {
  background-color: transparent !important;
}
.v-data-table >>> .td-rowspan {
  border-bottom: 1px solid #888888 !important;
}

/* ぐるぐる */
.action-message {
  white-space: pre-line;
}
.spinner {
  margin: 50px;
}
</style>
