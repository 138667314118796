<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>{{ surveyType.filter((e) => (e.value == targetSurveyType))[0].text }} 集計</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" sm="4" md="4" lg="4">
        <DatePicker
          label="集計年"
          v-model="overview.targetYear"
          format="YYYY年MM月"
          selectType="month"
          :clearable="false"
          :rules="[required]"
          :changeEvt="search"
        ></DatePicker>
      </v-col>
    </v-row>

    <!-- 冷媒調査結果 -->
    <!-- <v-form
      class="mt-5 mb-5"
      ref="formSum"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              冷媒調査結果
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetYear" @click="fileDownload(0, '冷媒調査結果', '/storage/refrigerant/■2020年度冷媒調査結果.xlsm')"><v-icon :small="true">mdi-download</v-icon>集計</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form> -->

  </v-container>
</template>

<script>
import Debug from '../Lib/Debug'
import DatePicker from "./common/DatePicker.vue";

export default {
  components: {
    DatePicker,
  },

  data: () => ({
    valid : true,
    loading: false,

    // 調査種別リスト
    surveyType: [
      {text: "12都市データ", value: 1},
      {text: "実態調査", value: 2},
      {text: "電力調査", value: 3},
      {text: "冷媒調査", value: 4},
    ],

    // 集計種別リスト
    sumType: [
      {text: "冷媒調査結果", value: 0},
    ],

    // 対象調査種別
    targetSurveyType: null,

    // 基本情報
    overview: {
      targetYear: "",
    },

  }),

  // ライフサイクルフック
  created: function () {
    this.targetSurveyType = this.$store.getters.getTargetSurveyType;
  },

  mounted: async function () {

  },

  methods: {
    loadData() {
      Debug.log("function[loadData]");
    },

    search() {
      Debug.log("function[search]");
      if(this.overview.targetYear == "") return;
      this.loadData();
    },
  },

  watch: {

  },
};
</script>

<style scoped>
/* ホバー効果を無効化 */
.no-hover-effect >>> tr:hover {
  background-color: transparent !important;
}
.v-data-table >>> .td-rowspan {
  border-bottom: 1px solid #888888 !important;
}
</style>
