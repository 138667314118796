<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>{{ surveyType.filter((e) => (e.value == targetSurveyType))[0].text }} 集計</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" sm="4" md="4" lg="4">
        <YearPicker
          label="集計年"
          v-model="overview.targetYear"
          format="YYYY年"
          selectType="month"
          :clearable="false"
          hide-details
          suffix="度"
          :changeEvt="search"
          :readonly="true"
        ></YearPicker>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4">
        <v-text-field
          label="日冷倉協会員事業所数"
          v-model="overview.memberCount"
          dense
          :readonly="true"
          :clearable="false"
          :rules="[]"
          :loading="loading"
          hide-details
          suffix="（調査時点）"
        ></v-text-field>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4">
        <v-text-field
          label="回答会員事業所数"
          v-model="overview.ansMemberCount"
          dense
          :readonly="true"
          :clearable="false"
          :rules="[]"
          :loading="loading"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- 冷蔵倉庫実態調査 全体 -->
    <v-form
      class="mt-5 mb-5"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              冷蔵倉庫実態調査 全体
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="250"  color="primary" :disabled="loading || !overview.targetYear" @click="getFactFindingDB()"><v-icon :small="true">mdi-download</v-icon>ダウンロード</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>

    <!-- 全体集計 -->
<!--
    <v-form
      class="mb-5"
      ref="formAll"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              全体集計
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetYear" @click="fileDownload(0, '冷蔵倉庫実態調査 全体', '/storage/factFinding/冷蔵倉庫実態調査_全体.xlsx')"><v-icon :small="true">mdi-download</v-icon>集計</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-data-table
              :loading="loading"
              :dense='true'
              :items-per-page="10"
              :mobile-breakpoint="0"
              :disable-pagination='true'
              :hide-default-header='true'
              :hide-default-footer='true'
              :fixed-header='true'
              disable-sort
              locale="ja-jp"
              loading-text="読込中"
              no-data-text="データがありません。"
              class="no-hover-effect table-border"
              >
                <template v-slot:body="{}">
                  <thead>
                    <tr>
                      <th :width="400"></th>
                      <th class="text-center">出力</th>
                      <th class="text-center">ブロック集計</th>
                    </tr>
                  </thead>
                  <tbody>
-->
                    <!-- ③基本指標(加重) -->
<!--
                    <tr>
                      <td class="left">
                        ③基本指標(加重)
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.weightingDisp"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.weightingSum"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                    </tr>
-->
                    <!-- ④基本指標(単純) -->
<!--
                    <tr>
                      <td class="left">
                        ④基本指標(単純)
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.simpleDisp"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                      <td class="center">
                      </td>
                    </tr>
-->
                    <!-- ⑤基本指標 ブロック加重 -->
<!--
                    <tr>
                      <td class="left">
                        ⑤基本指標 ブロック加重
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.blockWeightingDisp"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                      <td class="center">
                      </td>
                    </tr>
-->
                    <!-- ⑥各種数値 事業所 -->
<!--
                    <tr>
                      <td class="left">
                        ⑥各種数値 事業所
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.officeDisp"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.officeSum"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                    </tr>
-->
                    <!-- ⑦各種数値(県別) -->
<!--
                    <tr>
                      <td class="left">
                        ⑦各種数値(県別)
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.prefDisp"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                      <td class="center">
                      </td>
                    </tr>
-->
                    <!-- ⑧賃金 事業所 -->
<!--
                    <tr>
                      <td class="left">
                        ⑧賃金 事業所
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.incomeOfficeDisp"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.incomeOfficeSum"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                    </tr>
-->
                    <!-- ⑨賃金 地域 -->
<!--
                    <tr>
                      <td class="left">
                        ⑨賃金 地域
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.incomeAreaDisp"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                      <td class="center">
                      </td>
                    </tr>
-->
                    <!-- ランキング -->
<!--
                    <tr>
                      <td class="left">
                        ランキング
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.rankingDisp"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                      <td class="center">
                      </td>
                    </tr>
-->
                    <!-- ⑧賃金 事業所 (大企業) -->
<!--
                    <tr>
                      <td class="left">
                        ⑧賃金 事業所 (大企業)
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.incomeOfficeLargeDisp"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.incomeOfficeLargeSum"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                    </tr>
-->
                    <!-- ⑧賃金 事業所 (中企業) -->
<!--
                    <tr>
                      <td class="left">
                        ⑧賃金 事業所 (中企業)
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.incomeOfficeMediumDisp"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.incomeOfficeMediumSum"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                    </tr>
-->
                    <!-- ⑧賃金 事業所 (小企業) -->
<!--
                    <tr>
                      <td class="left">
                        ⑧賃金 事業所 (小企業)
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.incomeOfficeSmallDisp"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                      <td class="center">
                        <v-select
                          class="mt-0"
                          v-model="settingAllSum.incomeOfficeSmallSum"
                          :items="[
                            { text: '×', value: false },
                            { text: '○', value: true },
                          ]"
                          dense
                          :readonly="false"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></v-select>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
-->

    <!-- 個別企業集計 単年 -->
<!--
    <v-form
      class="mb-5"
      ref="formCompanyYear"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              個別企業集計 単年
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3">
              <v-select
                label="対象企業"
                v-model="targetCompany"
                :items="companyList"
                dense
                :readonly="false"
                :clearable="false"
                :rules="[required]"
                :loading="loading"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetYear" @click="fileDownload(1, '冷蔵倉庫実態調査 企業', '/storage/factFinding/冷蔵倉庫実態調査_企業.xlsx')"><v-icon :small="true">mdi-download</v-icon>集計</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>
-->
    <!-- 個別返答返信用 -->
<!--
    <v-form
      class="mb-5"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              個別返答返信用
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="250"  color="primary" :disabled="loading || !overview.targetYear || !(selectMember.length > 0)" @click="getMemberSum()"><v-icon :small="true">mdi-file</v-icon>個別・時系列ファイル作成</v-btn>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="250"  color="primary" :disabled="loading || !overview.targetYear || !isEnableDownload" @click="gotoReply()"><v-icon :small="true">mdi-send</v-icon>個別返答送信画面へ</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="mb-n7">
            <v-col cols="12">
              <v-checkbox
                label="すべて選択"
                v-model="allCheck"
                @change="all_check()"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table :headers="MemberSumHeaders" :items="memberList" hide-default-footer dense>
                <template v-slot:item="{ item }">
                  <tr>
                    <td><v-checkbox class="mb-2" v-model="selectMember" :value="item.memberNo" dense hide-details></v-checkbox></td>
                    <td>{{ item.companyName }}</td>
                    <td>{{ item.officeName }}</td>
                    <td>{{ item.memberNo }}</td>
                    <td>{{ item.companyId }}</td>
                    <td>{{ item.indivFileName }}</td>
                    <td>{{ item.tlFileName }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
-->
<!-- 
    <v-form
      class="mb-5"
      ref="formOffice"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              個別返答返信用
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetYear" @click="fileDownload(2, '個別返答', '/storage/factFinding/③個別返答（2020年度版）.xlsx')"><v-icon :small="true">mdi-download</v-icon>集計</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>
-->


    <!-- 個別企業集計 推移 -->
<!-- 
    <v-form
      class="mb-5"
      ref="formCompanyTran"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              個別企業集計 推移
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading || !overview.targetYear" @click="fileDownload(3, '冷蔵倉庫実態調査 企業 ２', '/storage/factFinding/冷蔵倉庫実態調査_企業_２.xlsx')"><v-icon :small="true">mdi-download</v-icon>集計</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>
-->


    <!------------------------------------------------------------------------>
    <!-- ぐるぐる -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" height="auto" id="vm--modal">
      <div>
        <h3 class="center mt-5 action-message">{{actionMessage}}</h3> 
        <Spinner size="150" class="spinner" ></Spinner>
      </div>
    </modal>

  </v-container>
</template>

<script>
import Debug from '../Lib/Debug'
import YearPicker from "./common/YearPicker.vue";
import Message from "../Lib/Message";
import Download from "../model/download";
import Spinner from 'vue-simple-spinner'

import saveAs from "file-saver";

import FactFinding from "../model/factFinding";
import FactFindingSum from "../model/factFindingSum";

export default {
  components: {
    YearPicker,
    Spinner,
  },

  data: () => ({
    valid : true,
    loading: false,

    // 調査種別リスト
    surveyType: [
      {text: "月次報告データ", value: 1},
      {text: "実態調査", value: 2},
      {text: "電力調査", value: 3},
      {text: "冷媒調査", value: 4},
    ],

    // 集計種別リスト
    sumType: [
      {text: "全体集計", value: 0},
      {text: "個別企業集計 単年", value: 1},
      {text: "個別返答返信用", value: 2},
      {text: "個別企業集計 推移", value: 3},
    ],

    // 対象調査種別
    targetSurveyType: null,

    // 基本情報
    overview: {
      targetYear: "",
      memberCount: 0,
      ansMemberCount: 0,
    },
    // 設定：全体集計
    settingAllSum: {
      weightingDisp: false,
      weightingSum: false,
      simpleDisp: false,
      blockWeightingDisp: false,
      officeDisp: false,
      officeSum: false,
      prefDisp: false,
      incomeOfficeDisp: false,
      incomeOfficeSum: false,
      incomeAreaDisp: false,
      rankingDisp: false,
      incomeOfficeLargeDisp: false,
      incomeOfficeLargeSum: false,
      incomeOfficeMediumDisp: false,
      incomeOfficeMediumSum: false,
      incomeOfficeSmallDisp: false,
      incomeOfficeSmallSum: false,
    },

    // 会社一覧
    companyList: [
      { text: "○○冷蔵", value: "010550" },
      { text: "▲▲冷蔵", value: "010080" },
      { text: "××冷蔵", value: "280074" },
      { text: "冷蔵", value: "000001" },
    ],
    // 対象会社
    targetCompany: "",

    actionMessage: '',

    // 回答データ一覧
    memberList: [],
    // 個別返答テーブル
    MemberSumHeaders: [
      { text: "送信", align: "center", sortable: true, value: "send", width: "5%" },
      { text: "会社名", align: "center", sortable: true, value: "companyName", width: "20%" },
      { text: "事業所名", align: "center", sortable: true, value: "officeName", width: "20%" },
      { text: "会員ID", align: "center", sortable: true, value: "memberNo", width: "10%" },
      { text: "登録番号", align: "center", sortable: true, value: "companyId", width: "10%" },
      { text: "個別ファイル", align: "center", sortable: true, value: "indivFileName", width: "15%" },
      { text: "時系列ファイル", align: "center", sortable: true, value: "tlFileName", width: "15%" },
    ],
    // 送信対象
    selectMember: [],
    // すべて選択
    allCheck: false,

    // 個別ファイル作成済
    isEnableDownload: false,
  }),

  // ライフサイクルフック
  created: function () {
    this.targetSurveyType = this.$store.getters.getTargetSurveyType;
    this.overview.targetYear = this.$route.params.targetYear;
    const answerDataList = JSON.parse(this.$route.params.answerDataList);
    let tmpMemberList = [];
    answerDataList.forEach(function (itemData) {
      const tmpMember = {
        companyName: itemData.companyName,
        officeName: itemData.officeName,
        memberNo: itemData.memberNo,
        companyId: itemData.companyId,
        indivFileName: '',
        tlFileName: '',
      };
      tmpMemberList.push(tmpMember);
    });
    this.memberList = tmpMemberList;
    Debug.log2('memberList:', this.memberList);

    // デフォルトは全チェック
    this.allCheck = true;
    this.all_check();
  },

  mounted: async function () {

  },

  methods: {
    async loadData() {
      Debug.log("function[loadData]");

      try {
        let res = null;
        // 集計基本情報
        res = await FactFinding.getSumOverview(this.overview.targetYear);

        if (res.status == 200 && res.data) {
          Debug.log(res.data);
          // 基本情報
          this.overview.memberCount = res.data.memberCount;
          this.overview.ansMemberCount = res.data.ansMemberCount;
        }
      } catch (error) {
        // alert('情報を取得できませんでした。')
        Message.err(error, "情報を取得できませんでした");
      }
    },

    search() {
      Debug.log("function[search]");
      if(this.overview.targetYear == "") return;
      this.loadData();
    },

    /** 冷蔵倉庫実態調査データ */
    async getFactFindingDB() {
      try {
        this.actionMessage = "冷蔵倉庫実態調査 全体を作成しています。\nしばらくお待ちください。"
        this.$modal.show('modal-dialog');

        // const res = await FactFindingSum.sumFactFindingDB(this.overview.targetYear);
        const res = await FactFindingSum.sumFactFindingDB(this.overview);

        if (res.status == 200 && res.data) {
          // 作成に成功したらExcelダウンロード
          Debug.log(res.data);
          const downloadFile = res.data;
          Download.download(downloadFile)
            .then(res => {
              saveAs(res.data, '冷蔵倉庫実態調査　全体.xlsx');
            })
            .catch((err) => {
              Message.err(err, "ダウンロードできませんでした");
          })
        }
      } catch (error) {
        Message.err(error, "冷蔵倉庫実態調査 全体を作成できませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }
    },

    /** 個別返答返信用・個別企業集計 */
    async getMemberSum() {
      // try {
      //   this.actionMessage = "個別返答返信用データを作成しています。\nしばらくお待ちください。"
      //   this.$modal.show('modal-dialog');

      //   const res = await FactFindingSum.sumFactFindingMemberSum(this.overview.targetYear, this.selectMember);

      //   if (res.status == 200 && res.data) {
      //     // 作成に成功したら一覧表示
      //     Debug.log(res.data);
      //     alert("個別返答返信用データを作成しました。");

      //     this.isEnableDownload = true;
      //   }
      // } catch (error) {
      //   Message.err(error, "個別返答返信用データを作成できませんでした");
      // } finally {
      //   this.$modal.hide('modal-dialog');
      // }
    },

    /** 個別返答送信画面 */
    gotoReply() {

    },

    // すべて選択
    all_check() {
      this.selectMember = [];
      if (this.allCheck) {
        this.memberList.forEach(el => {
          this.selectMember.push(el.memberNo);
        });
      }
    },
  },

  watch: {

  },
};
</script>

<style scoped>
/* ホバー効果を無効化 */
.no-hover-effect >>> tr:hover {
  background-color: transparent !important;
}
.v-data-table >>> .td-rowspan {
  border-bottom: 1px solid #888888 !important;
}

/* ぐるぐる */
.action-message {
  white-space: pre-line;
}
.spinner {
  margin: 50px;
}
</style>
